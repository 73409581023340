import { AppContext } from 'AppContext'
import { BootstrapData, BreakdownMode } from 'api'
import { Locale } from 'core/i18n/types'
import { SnowflakeType } from 'core/types'
import { useContext } from 'react'

/**
 * Here we provide a manual type for the boot object for all those properties
 * that are not defined in the bindings.
 *
 * Please add here the properties that you want to use.
 */
type Boot = BootstrapData & {
  config: {
    upload_max_filesize: number
    multilingual_language: Locale[]
    active_language?: Locale
    home_banner: string | null
    whiteboard_as_home: boolean
    schedule: {
      icons_theme: string
      optional_display: {
        taux_encadrement: boolean
      }
    }
    planning: {
      absence_requests_with_delete_schedule: boolean
      show_autocompletable: boolean
      configurable_adjustment_time: boolean
      optional_display_management_rate: boolean
      show_special_hours: boolean
      night_end: number
      night_start: number
      rest_work_time_len: number
      day_work_time_alert: {
        limit: number
      }
      night_work_time_alert: {
        limit: number
        len: number
      }
      weekly_rest_interval: number
    }
    whiteboard: {
      scriptsEnabled: boolean
    }
    timeclock: {
      break_time: boolean
      qualification: boolean
      breakdownMode: BreakdownMode
      isBreakdownConfigurablePerCollaborator: boolean
      autocorrection: boolean
    }
    activity: {
      breakdownMode: BreakdownMode
      isBreakdownConfigurablePerCollaborator: boolean
    }
    balances: {
      customCollaborator: boolean
    }
  }
  user: {
    id: number
    snowflake: SnowflakeType
    picture: string
    first_name: string | null
    last_name: string | null
    private_email: string | null
    work_email: string | null
    full_name: string
    head_sector_id: number
  }
  metadata: {
    instance: {
      name: string
    }
  }
  acl: {
    admin?: {
      config?: {
        allowed: boolean
      }
    }
    activity?: {
      manage?: {
        allowed: boolean
      }
    }
    project?: {
      create?: {
        allowed: boolean
      }
      manage?: {
        allowed: boolean
      }
    }
    tipee?: {
      systemadmin?: {
        allowed: boolean
      }
    }
    'schedule-template-types'?: {
      manage?: {
        allowed: boolean
      }
    }
  }
  topbar: {
    absences: {
      absencesCount: number
    }
  }
}

export const useBootstrap = () => {
  const boot = useContext(AppContext).boot
  if (!boot) {
    throw new Error('Boot is not defined')
  }

  return boot as Boot
}
