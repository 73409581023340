import { getActiveUser } from 'authentication/state/selectors'
import { parseDateTime } from 'chronos'
import { getConfigKey } from 'core/state/selectors'
import { compareAsc } from 'date-fns'
import { subState } from 'directory/state/selectors/subState'
import lodash from 'lodash'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { charmap } from 'utils/charmap'
import { LocalDate, LocalDateInterval } from 'chronos'

export const getPersons = (state: any) => subState(state).persons
export const getResourcesSummaries = (state: any) => subState(state).resourcesSummaries
export const getResourcesAccount = (state: any) => subState(state).resourcesAccount
export const getPersonsBySnowflake = (state: any) => subState(state).personsBySnowflake
export const getSearchbarText = (state: any) => subState(state).ui.searchbar.searchText
export const isFetching = (state: any) => subState(state).ui.persons.isFetching
export const searchedPersons = (state: any) => filterPersonsContainingFullNameOrSector(state, getSearchbarText(state))

export const isFetchingPerson = (state: any, id: any) => !!subState(state).ui.persons.personsFetching[id] || false
export const isFetchingResourcesSummaries = (state: any) => subState(state).ui.resourcesSummaries.isFetching
export const isFetchingResourceSummary = (state: any, snowflake: any) => {
  return !!subState(state).ui.resourcesSummaries.resourcesSummariesFetching[snowflake] || false
}
export const isFetchingResourceAccount = (state: any, snowflake: any) => {
  return !!subState(state).ui.resourcesAccount.resourcesAccountFetching[snowflake] || false
}
export const isPostingResourceUsername = (state: any) =>
  subState(state).ui.pages.settings.accountSettings.changeUsername.isPosting
export const saveResourceUsernamePostError = (state: any) =>
  subState(state).ui.pages.settings.accountSettings.changeUsername.error
export const isPostingMustChangePassword = (state: any) =>
  subState(state).ui.pages.settings.accountSettings.forceChangePassword.isPosting
export const saveMustChangePasswordPostError = (state: any) =>
  subState(state).ui.pages.settings.accountSettings.forceChangePassword.error
export const isPostingSendCredentialsLink = (state: any) =>
  subState(state).ui.pages.settings.accountSettings.sendCredentialsLink.isPosting
export const sendCredentialsLinkPostError = (state: any) =>
  subState(state).ui.pages.settings.accountSettings.sendCredentialsLink.error
export const isPostingBalanceMode = (state: any) =>
  subState(state).ui.pages.settings.balanceSettings.balanceMode.isPosting
export const saveResourceBalanceModePostError = (state: any) =>
  subState(state).ui.pages.settings.balanceSettings.balanceMode.error
export const isPostingWorkDisplayMode = (state: any) =>
  subState(state).ui.pages.settings.balanceSettings.workDisplayMode.isPosting
export const saveResourceWorkDisplayModePostError = (state: any) =>
  subState(state).ui.pages.settings.balanceSettings.workDisplayMode.error
export const isPostingTimecheckMode = (state: any) =>
  subState(state).ui.pages.settings.balanceSettings.timecheckMode.isPosting
export const saveResourceTimecheckModePostError = (state: any) =>
  subState(state).ui.pages.settings.balanceSettings.timecheckMode.error

export const getCustomFieldConfig = (state: any, role: any, customFieldKey: any) => {
  const config = getConfigKey(state, 'directory.custom_fields[' + role + ']')

  return config && config[customFieldKey] ? config[customFieldKey] : { is_active: false }
}

export const getPersonsBySector = createCachedSelector(
  getPersons,
  getActiveUser,
  (state: any, withMeAsFirstSector: any) => withMeAsFirstSector || false,
  (persons, activeUser, withMeAsFirstSector) => {
    //TODO: delete array after multiple main sectors bug is resolved (contracts refactoring)
    return lodash(persons)
      .map(person => {
        return { ...person.value }
      })
      .groupBy(person => {
        if (withMeAsFirstSector && activeUser.id === person.id) {
          return 'tipee.me'
        }

        const headSectorForToday = person.work.sectors.find(
          // @ts-expect-error (nocheck)
          sector => sector.head && LocalDateInterval.parse(sector.contract_date_range).containsDate(LocalDate.now())
        )

        return headSectorForToday ? headSectorForToday.name : 'tipee.without_head_sector'
      })
      .value()
  }
)((state, withMeAsFirstSector = false) => JSON.stringify(withMeAsFirstSector))

export const getPersonsBySectorAsOptions = createCachedSelector(getPersons, persons => {
  return lodash(persons)
    .map(person => {
      const sector = person.value.work.sectors.find(
        // @ts-expect-error (nocheck)
        sector => sector.head && LocalDateInterval.parse(sector.contract_date_range).containsDate(LocalDate.now())
      )

      return {
        id: person.value.id,
        snowflake: person.value.snowflake,
        fullName: person.value.general.full_name,
        mainsector: sector ? sector.name : 'tipee.without_head_sector',
      }
    })
    .value()
})(state => `persons_by_sector_as_options`)

export const getPerson = createCachedSelector(
  getPersons,
  getPersonsBySnowflake,
  (state: any, id: any) => id,
  (persons, personsBySnowflake, id) => {
    const person = persons[id] || personsBySnowflake[id]
    return person ? person.value : null
  }
)((state, id) => `person_${id}` || 'no_person')

export const getPersonRecapitulation = createCachedSelector(
  getPersons,
  getPersonsBySnowflake,
  (state: any, id: any) => id,
  (persons, personsBySnowflake, id) => {
    const personDatas = persons[id] || personsBySnowflake[id]
    if (!personDatas) return null

    const { value: person } = personDatas

    return {
      picture: person.general.picture,
      fullName: person.general.full_name,
      lastName: person.general.last_name,
      firstName: person.general.first_name,
      job: person.general.job,
      rate: person.work.rate,
      sectors: person.work.sectors,
      balanceMode: person.work.balance_mode,
      timecheckMode: person.work.timecheck_mode,
      regrouping: person.work.regrouping,
      isPaidHourly: person.work.is_paid_hourly,
      contractType: person.work.contract_type,
    }
  }
)((state, id) => `person_recapitulation_${id}` || 'no_person_recapitulation')

export const getResourceSummary = createCachedSelector(
  getResourcesSummaries,
  (state: any, snowflake: any) => snowflake,
  (resourcesSummaries, snowflake) => {
    const person = resourcesSummaries[snowflake]
    return person ? person.value : null
  }
)((state, snowflake) => `resource_summary_${snowflake}` || 'no_resource')

export const getResourceAccount = createCachedSelector(
  getResourcesAccount,
  (state: any, snowflake: any) => snowflake,
  (resourceAccount, snowflake) => {
    const account = resourceAccount[snowflake]
    return account ? account.value : null
  }
)((state, snowflake) => `resource_account_${snowflake}` || 'no_resource_account')

export const getPersonFactory = createSelector(
  (state: any) => state,
  state => lodash.memoize(id => getPerson(state, id))
)

export const isPersonDataExpired = createCachedSelector(
  getPersons,
  getPersonsBySnowflake,
  (state: any, id: any) => id,
  (persons, personsBySnowflake, personId) => {
    const person = persons[personId] || personsBySnowflake[personId]
    if (!person) {
      return true
    }
    return !person.expiration || compareAsc(person.expiration, parseDateTime()) <= 0
  }
)((state, id) => `is_person_data_expired_${id}`)

export const isResourceSummaryDataExpired = createCachedSelector(
  getResourcesSummaries,
  (state: any, snowflake: any) => snowflake,
  (resourcesSummaries, snowflake) => (resourcesSummaries ? !resourcesSummaries[snowflake] : true)
)((state, snowflake) => `is_resource_summary_data_expired_${snowflake}`)

export const isResourceAccountDataExpired = createCachedSelector(
  getResourcesAccount,
  (state: any, snowflake: any) => snowflake,
  (resourcesAccount, snowflake) => (resourcesAccount ? !resourcesAccount[snowflake] : true)
)((state, snowflake) => `is_resource_account_data_expired_${snowflake}`)

export const filterPersonsContainingFullNameOrSector = createCachedSelector(
  getPersons,
  (state: any, fullName: any) => fullName,
  (persons, fullName) => {
    const mappedFullName = charmap(fullName)
    return lodash
      .chain(persons)
      .pickBy(person => {
        return (
          person.value.general.full_name.toLowerCase().match(mappedFullName) !== null ||
          person.value.general.first_name.toLowerCase().match(mappedFullName) !== null ||
          person.value.general.last_name.toLowerCase().match(mappedFullName) !== null ||
          (
            person.value.work.sectors.find(
              // @ts-expect-error (nocheck)
              sector => sector.head && LocalDateInterval.parse(sector.contract_date_range).containsDate(LocalDate.now())
            ) || {
              name: '',
            }
          ).name
            .toLowerCase()
            .match(mappedFullName) !== null
        )
      })
      .map(person => person.value)
      .value()
  }
)((state, fullName) => `filter_persons_containing_full_name_or_sector_${fullName}`)

export const canViewConfidential = createCachedSelector(
  getPerson,
  person => !person || !person.confidential || person.permissions.can_view_confidential
)((state, personId) => 'can_view_confidential_' + JSON.stringify(personId))

export const supportListIsFetching = (state: any) => subState(state).ui.supportList.isFetching
export const getSupportList = createSelector(
  getPersons,
  (state: any) => subState(state).supportList,
  (persons, supportList) => {
    return lodash(supportList)
      .map(sector => ({
        ...sector,
        persons: lodash.map(sector.persons, person => persons[person].value),
      }))
      .value()
  }
)
